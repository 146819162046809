body {
  margin: 0;
  font-family: Montserrat;
  background-color: #2041e0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  background-image: url(./assets/images/xdefi-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: top;
  min-height: 100vh;
  min-width: 100%;
  max-width: 1120px;
  height: 100%;
}

.home-container {
  width: 100%;
  margin: auto;
}

.header {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 11px;
  align-items: center;
}

.header .logo img {
  width: 216px;
}

.left-nav {
  display: flex;
  align-items: center;
  width: 50%;
}

.nav-items {
  width: 100%;
}

.nav-items ul {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}

.nav-items ul li {
  margin: 0 7px;
  list-style: none;
}

.nav-items ul li a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: white;
}

a {
  cursor: pointer;
}
.error-btn {
  background: #ab0606 !important;
  color: white !important;
}

.error-btn:hover {
  background: #890505 !important;
}
.header {
  width: 95%;
}

h1 {
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

hr.new2 {
  border: none;
  margin: 0;
  padding: 0;
}

hr.new4 {
  max-width: 992px;
  border-top: 1px dotted white;
  border-bottom: none;
  border-left: none;
}

hr.new5 {
  border-top: 1px dotted #979797;
  border-bottom: none;
  margin: 16px 0;
}

hr.new2:after {
  content: '.........................................................................................';
  letter-spacing: 1px;
}

.main-container {
  max-width: 370px;
  margin: auto;
  text-align: center;
  color: #ffffff;
}

.token-img {
  width: 98px;
}

.error {
  width: 262px;
  text-align: right;
  margin: 5px 11px 35px;
  color: #ff7452;
}

.network-error {
  margin: 20px auto;
  color: #ff7452;
}

.address-input-field {
  outline: #ff7452 solid 2px !important;
}

.pending-claim-lable {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.pending,
.claim {
  height: 77px;
  width: 160px;
  background: #ffffff33;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  top: 467px;
  border-radius: 7px;
}

.pending p,
.claim p {
  display: flex;
  text-transform: uppercase;
  padding: 5px;
  flex-direction: column;
}

.p-text {
  font-size: 10px;
  padding: 10px;
}

.connect-btn .connect:hover {
  background: #ecf1fd;
}

.connect-btn button {
  width: 160px;
  height: 40px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 3px;
  color: #2041e0;
  background: #ffffff;
  border: none;
  cursor: pointer;
  line-height: 15px;
  text-align: center;
  padding: 8px;
}

.input-box {
  display: flex;
  justify-content: space-between;
}

.input-placeholder {
  width: 100%;
}

.input-placeholder:hover {
  background: #d8d8d8;
}

.input-placeholder:disabled {
  background: #d8d8d8;
  cursor: not-allowed;
}

.xdefi-placeholder {
  position: absolute;
  transform: translate(100%, 100%);
  top: 0;
  left: 65%;
  font-size: 12px;
  color: #979797;
  line-height: 16px;
  font-weight: 500;
}

.input-box input {
  height: 44px;
  padding-left: 12px;
  width: 296px;
  outline: none;
  font-size: 14px;
  color: #333333 !important;
  border: none;
  background: #ffffffcc;
  font-weight: 400;
  border-radius: 3px;
  line-height: 20px;
}

.input-box button {
  height: 44px;
  cursor: pointer;
  width: 64px;
  margin-left: 5px;
  padding: 5px;
  border-radius: 3px;
  color: white;
  background: #172777;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  border: none;
}

.input-text {
  margin: 24px auto;
  font-size: 14px;
}

.released-date {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-top: 25px;
}

.released-date p:first-child {
  width: 160px;
  text-align: right;
}

.total-vested-tokens,
.next-release-date {
  width: 160px !important;
  text-align: left !important;
  margin-bottom: 8px;
}

.claimable-token-contaner p {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 45px;
}

.claimable-token {
  font-size: 28px;
  font-family: Montserrat;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 32px;
}

.claim-btn {
  height: 44px;
  width: 296px;
  background: #ffffff;
  border: none;
  margin-bottom: 25px;
  font-size: 20px;
  color: blue;
  border-radius: 3px;
  cursor: pointer;
}

.zero-token {
  margin: 40px 0;
}

.footer {
  margin: auto;
  width: fit-content;
  padding: 20px 0;
}

.footer a {
  color: white;
  margin-left: 12px;
}

.date-token {
  text-align: left;
}

.date-token span {
  font-size: 14px;
  color: #d8d8d8;
}

.username-icon {
  display: flex;
  align-items: center;
}

.username-icon p {
  color: #ffffff;
  margin-right: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400px;
  border-radius: 3px;
  background: #ffffff33;
  padding: 10.5px 20px;
}

.username-icon .success-btn {
  background: unset;
  letter-spacing: 0.25px;
  font-weight: 500;
  border: solid 1px #ffffff;
  color: #ffffff;
}

.success-btn:hover {
  background: #ffffff57;
}

.table-container {
  margin-top: 40px;
}

.table-data {
  margin: auto;
}

.table-data tr td:first-child {
  text-align: right;
}

.table-data tr td:nth-child(2) {
  width: 30px;
}

.table-data td {
  vertical-align: top;
}

.table-data p {
  margin: unset;
}

.staking-container {
  max-width: 880px;
  padding-bottom: 40px;
  margin: auto;
  text-align: center;
  color: white;
}

.staking-component {
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
}

.heading-text {
  margin: auto;
}

.heading-text p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.heading-text a {
  color: rgb(0, 0, 238);
  padding: 0 2px;
}

.stake-alert svg {
  margin-right: 32px;
  flex-shrink: 0;
}

#lockboostTip,
#aprTip {
  width: 240px;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  background: white;
  text-align: left;
  color: #333333;
}

#registerTip {
  width: 164px;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  background: white;
  text-align: left;
  color: #333333;
}

#registerTip::after {
  border-top-color: white !important;
}

#instability {
  width: 164px;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  background: white;
  text-align: left;
  color: #333333;
}

#instability::after {
  border-top-color: white !important;
}

.staking-apr,
.staking-balance {
  width: 256px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px 16px;
  box-sizing: border-box;
  border-radius: 7px;
  background: #ffffff33;
}

.staking-apr p,
.staking-balance p:first-child,
.staking-balance .rexdefi {
  margin: 0;
  line-height: 32px;
}

.staking-apr span {
  font-size: 28px;
  font-weight: 500;
}

.rexdefi {
  display: flex;
}

.rexdefi img {
  height: 48px;
  width: 48px;
  margin-right: 15px;
}

.rexdefi p {
  color: #c4c4c4;
  font-size: 12px;
  line-height: normal;
  margin: 0;
}

.rexdefi span {
  font-size: 20px;
  font-weight: 400;
}

.withdrawn {
  color: #36b37e;
}

.total-xdefi-container {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #8a98b6;
}

.total-xdefi p {
  font-size: 12px;
  color: #c4c4c4;
}

.total-xdefi h4 {
  font-size: 14px;
}

.horizontal-line {
  margin: 20px auto;
  border-right: 1px dashed #b6a9a9;
}

.stake-inputs-container {
  width: 256px;
  margin: 0 auto;
}

.stake-tab {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.lock-period {
  margin-bottom: 5px;
  margin-top: 24px;
  line-height: 22px;
  font-size: 14px;
}

.stake-tab input {
  background: #f2f1f1;
  border-radius: 3px;
  height: 44px;
  width: 100%;
  outline: none;
  border: none;
  padding-left: 16px;
  font-size: 14px;
  line-height: 20px;
}

.stake-tab label {
  margin-bottom: 8px;
}

.stake-label input[type='range'] {
  width: 100%;
}

.stake-tab .stake-label:first-child {
  margin-top: 0;
}

.stake-tab .stake-label:first-child p {
  margin-top: 0;
}

.stake-label {
  display: flex;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: -8px;
}

.input-placeholder::placeholder {
  text-align: left;
  text-align-last: justify;
  padding-right: 8px;
}

.stake-button {
  height: 44px;
  color: white;
  font-size: 16px;
  line-height: 20px;
  border: none;
  margin-top: 24px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  background: #172777;
}

#view-data-button:hover,
.stake-button:hover {
  background: #071a58;
}

.vertical-dashed-line {
  border-top: dashed 1px #9ea7ad;
  margin: 30px 0;
}

.nav-container {
  display: flex;
}

.nav-container > li {
  margin-left: 49px !important;
}

.mobile-menu {
  display: none;
}

@media screen and (max-width: 992px) {
  .home-container {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .home-container {
    width: 100%;
  }

  .staking-container {
    max-width: 95%;
  }

  .stake-inputs-container {
    width: 230px;
  }

  .total-xdefi-container {
    flex-direction: column;
  }

  .horizontal-line {
    border-right: unset;
    border-bottom: 1px dashed #b6a9a9;
    margin: auto 21px;
  }

  /* .staking-apr,
  .staking-balance {
    width: 130px;
  } */

  .header {
    padding: 0px 10px;
  }

  .nav-options {
    display: flex;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    background: #2b67e6;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vw;
  }

  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }

  .mobile-menu {
    cursor: pointer;
    display: block;
    margin-right: 18px;
  }

  .mobile-menu svg {
    color: white;
    width: 40px;
  }

  .connect-btn {
    margin: 15px 0;
  }

  .input-para {
    white-space: unset !important;
    margin: 40px auto !important;
  }

  .nav-container {
    flex-direction: column;
  }

  .nav-container > li {
    margin-left: unset !important;
  }

  .staking-component {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 580px) {
  /* .staking-apr,
  .staking-balance {
    width: 200px;
  } */

  .right-pane {
    margin-top: 30px;
  }

  .table td,
  .table th {
    width: 100px !important;
    padding: 0 !important;
  }
}

.btn-disabled {
  background: #979797;
  cursor: not-allowed;
}

.btn-disabled:hover {
  background: #ffffffa3;
}

.spinner-container span {
  margin: 0 2px !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container span {
  margin: 0 25px;
}

.spinner-container svg {
  width: 20px !important;
  border-left: solid 1px;
  padding-left: 20px;
}

.table-loader-container {
  width: 100%;
  padding-bottom: 100px;
}

.tabler-loader {
  width: 120px;
  height: 120px;
  background: #232323;
  border-radius: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.stake-button:disabled {
  cursor: not-allowed;
}

.stake-label-value:hover {
  cursor: pointer;
  text-decoration-line: underline;
}

#progressBar {
  background: #232323;
  width: 120px;
  height: 10px;
  border: none;
}

#progressBar::-moz-progress-bar {
  background: #ecf1fd !important;
  width: 100%;
  height: 10px;
  margin: auto;
}

progress::-webkit-progress-bar {
  width: 100%;
  margin: auto;
  height: 10px;
  background-color: #232323;
}

progress::-webkit-progress-value {
  height: 10px;
  float: left;
  background-color: #ecf1fd;
}

.Toastify__toast-body .Toastify__toast-icon {
  display: none !important;
}

.error-toast {
  border-left: solid 5px red;
}

.success-toast {
  border-left: solid 5px #07bc0c;
  background: indigo;
}

.Toastify__close-button {
  width: auto !important;
  background: transparent !important;
  color: white !important;
}

.Toastify__toast {
  background: linear-gradient(227.58deg, #172777 24%, #333333 100%) !important;
  background-color: linear-gradient(
    227.58deg,
    #172777 24%,
    #333333 100%
  ) !important;
}

.success-toast-container h4 {
  margin: auto;
  font-weight: 400;
  color: #07bc0c;
}

.error-toast-container h4 {
  margin: auto;
  font-weight: 400;
  color: red;
}

.error-toast-container p {
  margin: auto;
  color: white;
}

.success-toast-container a,
.error-toast-container a {
  color: #4476f2;
  text-decoration: none;
}

.success-toast-container a:hover,
.error-toast-container a:hover {
  text-decoration: underline;
}

.success-toast-container .vertical-dashed-line {
  margin: 15px 0;
}

.nft-reward {
  display: flex;
  align-items: center;
}

.nft-reward img {
  width: 40px;
  margin-right: 16px;
}
.nft-reward-earned {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
}

.nft-reward a {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 17px;
}

.table {
  margin: auto;
  border-spacing: 0;
}

.table th {
  height: 64px;
  width: 132px !important;
  font-weight: bold;
  border-top: solid 1px #f2f1f1;
  border-bottom: solid 1px #f2f1f1;
}

.table td {
  height: 79px;
  width: 132px !important;
  border-bottom: dotted 1px #687fd5;
  color: lightgray;
  line-height: 20px;
}

.table td,
.table th {
  text-align: center;
  font-size: 14px;
  width: 156px;
}

.table th:nth-child(even),
.table td:nth-child(even) {
  background: rgba(35, 35, 35, 0.1);
}

/* .table tr:nth-child(1) td {
  border-top: none;
} */
.stake-table-container {
  text-align: center;
  color: white;
  max-width: 948px;
  margin: auto;
  padding-bottom: 100px;
}
.stake-table-container h2 {
  margin: 41px 0;
}

.total-stake {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
}

.total-stake svg {
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.withdrawd-col svg {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.slider-parent {
  width: 100%;

  position: relative;
}

.buble {
  background: #172777;
  color: white;
  padding: 5px 11px;
}

.buble-container {
  margin-top: -10px;
  text-align: center;
  position: absolute;
  width: 55px;
}

.buble-container svg {
  width: 17px;
  height: 17px;
  margin-bottom: -10px;
  fill: #172777;
}

.input-para {
  margin-bottom: 40px;
  margin-top: 24px;
  margin-left: -38%;
  font-size: 14px;
  border-radius: 2px;
  white-space: nowrap;
}

.h64 {
  height: 64px;
}

.ethereum-add {
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin: 5px 0;
}

/* =======================New navbar=========================== */
.logo-nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.logo-container {
  align-items: center;
  justify-content: center;
}

.logo {
  width: 45px;
  height: 45px;
}

.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  list-style-type: none;
}

.mobile-option {
  display: none;
}

.option :hover {
  color: white;
}

.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
}

.sign-in {
  padding-right: 50px;
  align-self: center;
}

.sign-in :hover {
  color: white;
}

.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  background: rgb(222, 9, 241);
  color: white;
  cursor: pointer;
  align-items: center;
}

.hide-withdrawn {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: right;
}

.hide-withdrawn input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 2px;
}

.hide-withdrawn label {
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
  line-height: 16px;
}

.custom-bg {
  appearance: unset;
  background-color: #979797;
}

.next-previous-btn {
  width: 131px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.next-previous-btn button {
  width: 32px;
  height: 32px;
  background: transparent;
  border-radius: 3px;
  outline: none;
  border: 1px solid #ffffff;
}

.next-previous-btn button svg {
  fill: #ffffff;
}

.next-previous-btn span {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.next-previous-btn button:hover {
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}

.next-previous-btn button:disabled,
.next-previous-btn button:disabled svg {
  border-color: #979797;
  fill: #979797;
}

.table-heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.dropbtn {
  background-color: #f2f1f1;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  line-height: 20px;
  border-radius: 3px;
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  cursor: pointer;
}

.dropbtn:hover {
  background: #d8d8d8;
}

.dropbtn svg {
  width: 28px;
  fill: #979797;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #ffffff;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  margin-top: 165px;
  width: 256px;
  border-radius: 4px;
}

.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background: #ecf1fd;
}

.show {
  display: block;
}

#myDropdown {
  padding-inline-start: 0 !important;
}
